<template>
	<div class="login-container">
		<form class="login form-signin" v-on:submit.prevent="attemptLogin">
			<div class="logo-container">
				<a href="https://concrete-elephant.com/">
					<img class="icon" src="@/assets/images/concrete-elephant-logo.svg">
				</a>
			</div>
			<regular-input v-model="email" type="email" label="E-mail" class="w-mb-10" :error="emailError" />
			<regular-input v-model="password" type="password" label="Password" class="w-mb-20" :error="passwordError" />
			<div v-if="authError !== ''" class="error w-mb-20">{{authError}}</div>
			<div class="form-buttons">
				<standard-button text="Log in" :loader-active="loginLoader" @click.native="attemptLogin" />
			</div>
			<div class="link-btn-container">
				<router-link :to="{name:'forgot-password',params: {lang: $i18n.locale}}" ><div class="link-btn">{{$t('sentences.forgotPassword')}}</div></router-link>
				<router-link :to="{name:'create-company',params: {lang: $i18n.locale}}" ><div class="link-btn">{{$t('sentences.createAccount')}}</div></router-link>
			</div>
			<!--Submit button must be present to allow submit on enter-->
			<button type="submit" class="hidden"></button>
		</form>
	</div>
	<!--<div class="text-center">
		<form class="form-signin">
			<img class="mb-4" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72">
			<h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
			<label for="inputEmail" class="sr-only">Email address</label>
			<input type="email" id="inputEmail" class="form-control" placeholder="Email address" required="" autofocus="">
			<label for="inputPassword" class="sr-only">Password</label>
			<input type="password" id="inputPassword" class="form-control" placeholder="Password" required="">

			<standard-button text="Log in" :loader-active="loginLoader" @click.native="attemptLogin" />
		</form>
	</div>-->
</template>

<script>
	import RegularInput from "@/components/inputs/RegularInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import gtmHandler from "@/mixin/gtmHandler";
	export default {
		name: "Login",
		components: {StandardButton, RegularInput},
		mixins: [gtmHandler],
		data(){
			return {
				email: '',
				password: '',
				emailError: '',
				passwordError: '',
				authError: '',
				loginLoader: false
			}
		},
		methods: {
			attemptLogin(){
				if(this.loginLoader) return;
				
				this.validateInputs();
				if(this.emailError === '' && this.passwordError === '')
				{
					this.loginLoader = true;
					this.authError = '';
					this.$store.dispatch('concrete/login',{email:this.email, password:this.password})
					.then(result => {
						this.gtm_fireLogin();
						this.$router.push({"name":"admin",params: {lang: result.locale}});
					})
					.catch(error => {
						if(typeof error.data !== 'undefined' && typeof error.data.error !== 'undefined')
						{
							if(error.data.error === 'Invalid credentials.')
							{
								this.authError = 'Your email or password is incorrect. Please try again.';
							}
							else
							{
								this.authError = 'An error occurred. Please try again.';
							}
						}
						else
						{
							this.authError = 'An error occurred. Please try again.';
						}
					})
					.then(() => {
						this.loginLoader = false;
					},() => {
						this.loginLoader = false;
					})
				}
			},
			validateInputs(){
				this.emailError = '';
				this.passwordError = '';
				if(this.email === '')
					this.emailError = 'Please provide a email';
				
				if(this.password === '')
					this.passwordError = 'Please provide a password';
			}
		}
	};
</script>

<style lang="scss" scoped>
	.login-container {
		background-color: $loginBackground;
		bottom: 0;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		padding: 10px;
		overflow: hidden;
		display: flex;
		align-items: center;
		.logo-container {
			display: flex;
  			align-items: center;
			justify-content: center;
			margin-bottom: 32px;
			.icon {
				width: 200px;
				height: 108px;
			}
		}
	}
	.form-signin {
		width: 100%;
		max-width: 495px;
		padding: 60px 42px 59px 42px;
		margin: 0 auto;
		background-color: $bg;
		border-radius: 5px;

		.hidden{
			display: none;
		}
	}
	.link-btn-container{
    	display: flex;
		justify-content: space-between;
		padding-top: 2rem;
		.link-btn{
			color: $loginLinkBtn;
			text-decoration: underline;
		}
	}
</style>
